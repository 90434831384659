<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div>
        <div class="logo ml-10">
          <router-link to="/"
            ><img class="logo-image" src="../assets/logo-farmaenlace.svg"
          /></router-link>
        </div>
        <nav class="md:block hidden">
          <ul>
            <li>
              <router-link to="/">Inicio</router-link>
            </li>
            <li>
              <router-link to="/avances"> Revisa tu avance</router-link>
            </li>
            <!-- <li>
            <a href="legales.html" target="_blank">Términos y condiciones</a>
          </li> -->
          </ul>
        </nav>
      </div>
      <div class="veneer text-white text-2xl">{{ fullname }}</div>
      <div
        class="
          md:hidden
          uppercase
          mt-10
          titulo-avance
          veneer
          md:mx-20
          mx-4
          md:px-20
          px-4
          py-5
        "
      >
        llega al 101% de tu cupo <br />y ganas un pavo para tu cena
        navideña o fin de año
      </div>
      <div class="md:flex mt-10 mx-auto md:px-20 termometro-master">
        <div class="termometro-container">
          <div class="termometro-big">
            <img
              id="termometro1"
              class="barras-termometro clip-svg"
              src="../assets/barra-termometro.png"
            />
            <div class="porcentajes porcentaje1 text-white veneer">
              {{ Math.floor(scale1 * 100) }}%
            </div>
            <svg width="0" height="0">
              <defs>
                <clipPath id="myClip" :style="cssVars">
                  <rect x="0" y="0" width="100" height="575"></rect>
                </clipPath>
              </defs>
            </svg>
            <img
              class="md:mx-0 mx-auto"
              src="../assets/termometro-cuerpo.png"
            />
          </div>
        </div>
        <div>
          <div>
            <div
              class="
                hidden
                md:block
                uppercase
                titulo-avance
                veneer
                md:mx-20
                mx-4
                md:px-20
                px-4
                py-5
              "
            >
              llega al 101% de tu cupo <br />y ganas un pavo para tu cena
              navideña o fin de año
            </div>
            <div class="grid grid-cols-6 md:mx-8 mx-2 my-8">
              <div class="termometros md:col-span-1 col-span-2">
                <img
                  id="termometro1"
                  class="barras-termometro-small clip-svg2"
                  src="../assets/barra-termometro.png"
                />
                <div class="porcentajes porcentaje1 text-white veneer">
                  {{ Math.floor(scale2 * 100) }}%
                </div>
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="myClip2" :style="cssVars">
                      <rect x="0" y="0" width="100" height="300"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <img
                  class="termometro-small mx-auto"
                  src="../assets/termometro-cuerpo.png"
                />
                <div class="uppercase veneer text-white">Megalabs</div>
              </div>
              <div class="termometros md:col-span-1 col-span-2">
                <img
                  id="termometro1"
                  class="barras-termometro-small clip-svg3"
                  src="../assets/barra-termometro.png"
                />
                <div class="porcentajes porcentaje1 text-white veneer">
                  {{ Math.floor(scale3 * 100) }}%
                </div>
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="myClip3" :style="cssVars">
                      <rect x="0" y="0" width="100" height="300"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <img
                  class="termometro-small mx-auto"
                  src="../assets/termometro-cuerpo.png"
                />
                <div class="uppercase veneer text-white">sanofi</div>
              </div>
              <div class="termometros md:col-span-1 col-span-2">
                <img
                  id="termometro1"
                  class="barras-termometro-small clip-svg4"
                  src="../assets/barra-termometro.png"
                />
                <div class="porcentajes porcentaje1 text-white veneer">
                  {{ Math.floor(scale4 * 100) }}%
                </div>
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="myClip4" :style="cssVars">
                      <rect x="0" y="0" width="100" height="300"></rect>
                    </clipPath>
                  </defs>
                </svg>
                <img
                  class="termometro-small mx-auto"
                  src="../assets/termometro-cuerpo.png"
                />
                <div class="uppercase veneer text-white">
                  tecnoquímicas del ecuador s.a.
                </div>
              </div>
              <div class="hidden md:block col-span-3">
                <img src="../assets/pavo.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <p class="text-white text-center">
          Importante: El termómetro se actualiza cada viernes.
        </p>
        <p class="text-white text-center">
          Aplican términos y condiciones. Promoción válida de 15 de octubre al
          31 de diciembre.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import gql from "graphql-tag";
export default {
  name: "Home",
  data() {
    return {
      fullname: "",
      scale1: 0.01,
      scale2: 0.01,
      scale3: 0.01,
      scale4: 0.01,
    };
  },
  components: {
    // HelloWorld
  },
  methods: {
    getMetas() {
      this.$apollo
        .query({
          query: gql`
            query usermeter($id: String!) {
              usermeter(id: $id) {
                fullname
                email
                m1
                q1
                c1
                p1
                m2
                q2
                c2
                p2
                m3
                q3
                c3
                p3
                qTotal
                cTotal
                pTotal
              }
            }
          `,
          variables: {
            id: this.user_id,
          },
        })
        .then((result) => {
          console.log(result.data.usermeter);
          let data = result.data.usermeter;
          this.fullname = data.fullname;
          this.scale1 = data.pTotal / 100;
          this.scale2 = data.p1 / 100;
          this.scale3 = data.p2 / 100;
          this.scale4 = data.p3 / 100;
        });
    },
    gotoIngresa() {
      this.$store.state.redirect = "/ingreso-factura";
      this.$router.push("/ingreso-factura");
    },
    gotoRegistro() {
      this.$router.push("/registro");
    },
    gotoJackpot() {
      this.$store.state.redirect = "/jugar";
      this.$router.push("/jugar");
    },
  },
  computed: {
    cssVars() {
      return {
        "--scale1": this.scale1,
        "--scale2": this.scale2,
        "--scale3": this.scale3,
        "--scale4": this.scale4,
      };
    },
    // scale1(){
    //     var a="transform:scaleY(0.65)"
    //     return{
    //         a
    //     }

    // }
  },
  mounted() {
    // var parent = this;
    this.user_id = this.$store.state.user_id;
    console.log(this.user_id);
    this.getMetas();
    // setTimeout(function () {
    //   console.log("running");
    //   parent.scale1 = 0.99;
    //   parent.scale2 = 0.3;
    //   parent.scale3 = 0.6;
    //   parent.scale4 = 0.9;
    // }, 1000);
  },
};
</script>
<style scoped>
.porcentajes {
  font-size: 20px;
  font-weight: bold;
}
.termometro-master {
  max-width: 1200px;
  padding-top: 50px;
}
#myClip {
  transform: scaleY(var(--scale1));
  transition: all 1s;
}

#myClip2 {
  transform: scaleY(var(--scale2));
  transition: all 1s;
}
#myClip3 {
  transform: scaleY(var(--scale3));
  transition: all 1s;
}
#myClip4 {
  transform: scaleY(var(--scale4));
  transition: all 1s;
}
.clip-svg {
  clip-path: url(#myClip);
}
.clip-svg2 {
  clip-path: url(#myClip2);
}
.clip-svg3 {
  clip-path: url(#myClip3);
}
.clip-svg4 {
  clip-path: url(#myClip4);
}
#first {
  background: url("../assets/fondo.jpg");
  overflow: hidden;
  min-height: 100vh;
}

.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
}

.titulos {
  color: white !important;
}

.titulo-avance {
  font-size: 30px;
  line-height: 31px;
  color: white;
  border: 2px solid #d60707;
  border-radius: 25px;
}
.termometro-container {
  width: 150px;
  position: relative;
}
.termometro-big {
  width: 150px;
}
.termometros {
  margin: 0 20px;
  position: relative;
}
.barras-termometro {
  position: absolute;
  left: 48px;
  top: 41px;
  transform: rotate(180deg);
  width: 44px;
}
.barras-termometro-small {
  position: absolute;
  left: 32px;
  top: 39px;
  transform: rotate(180deg);
  width: 29px;
}
.green {
  color: #008126;
}

nav {
  position: absolute;
  top: 20px;
  right: 100px;
  background: #d60707;
  border-radius: 15px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
a {
  cursor: pointer;
}

h2 {
  color: #231f20;
  font-size: 34px;
  font-weight: bold;
}
.termometro-small {
  max-width: 100px;
}
@media only screen and (max-width: 767px) {
  .barras-termometro {
    left: 148px;
    top: 44px;
    width: 50px;
  }
  .barras-termometro-small {
    left: 38px;
  }
  .termometros {
    margin: 0 0px;
  }
  .termometro-container {
    width: 100%;
    position: relative;
  }
  .termometro-big {
    width: 100%;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  .pasos-participar h3 {
    font-size: 17px;
  }
  .pasos-participar span {
    font-size: 14px;
  }
  .listado-icono {
    max-height: 35px;
    margin-right: 10px;
  }
  .listado-numero {
    max-height: 50px;
  }
  #first {
    overflow: none;
    height: inherit;
  }
}
</style>
